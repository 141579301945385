@import "../themes/variables.less";

/* Collapse */
.dReLifecycle-container .collapsible-wrapper {
  display: flex;
  flex-direction: column;
  background: @dre-off-white-2;
  margin: 0 0 29px 0;
  border-radius: 12px;
  border: 1px solid @dre-rgba-color;
  overflow: hidden;
  box-shadow: 0px 46.7182px 70.487px @dre-rgba-color-3, 0px 19.5177px 29.4478px @dre-rgba-color-7,
    0px 10.4351px 15.7442px @dre-rgba-color-6, 0px 5.84984px 8.82607px @dre-rgba-color-2,
    0px 3.1068px 4.68746px @dre-rgba-color-5, 0px 1.29281px 1.95056px @dre-rgba-color-4;

  max-height: 105px;
  transition: all 0.5s;
}

.dReLifecycle-container .collapsible-wrapper.open {
  max-height: 2000px;
  transition: all 0.5s;
}
