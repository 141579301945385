@import "../themes/variables.less";

/* Cards */
.dReLifecycle-container .collapsible-wrapper .card {
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid @dre-rgba-color;
  border-radius: 12px;
}

.dReLifecycle-container .collapsible-wrapper .card-header {
  background: @dre-off-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 34px;
}

.dReLifecycle-container .collapsible-wrapper .card-content {
  background: @dre-default-white;
  padding: 24px 34px;
  flex: 1 1 0%;
}
