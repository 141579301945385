/* Modal */
.modal-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.modal-content ol li:not(:last-child) {
  margin-bottom: 15px;
}
