@import "../themes/variables.less";

.dReLifecycle-container .collapsible-wrapper .collapsible-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: @dre-off-white;
  border-radius: 12px;
  padding: 20px 30px;
  cursor: pointer;
  box-shadow: 0px 46.7182px 70.487px @dre-rgba-color-3, 0px 19.5177px 29.4478px @dre-rgba-color-7,
    0px 10.4351px 15.7442px @dre-rgba-color-6, 0px 5.84984px 8.82607px @dre-rgba-color-2,
    0px 3.1068px 4.68746px @dre-rgba-color-5, 0px 1.29281px 1.95056px @dre-rgba-color-4;

  font-size: 15px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header .contract-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header .contract-status h3 {
  width: 187px;
  font-size: 18px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header .contract-status.pending h3 {
  color: @dre-orange;
}

/* Collapsible Arrow */
.dReLifecycle-container .collapsible-header .contract-status .chevron-open {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5.94595px @dre-rgba-color, 0px 0px 5.94595px @dre-rgba-color;
  border-radius: 60px;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-open.pending-chevron {
  background: @dre-orange;
  border: 3px solid @dre-orange;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-open.active-chevron {
  background: @dre-green;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-open.active-chevron svg,
.dReLifecycle-container .collapsible-header .contract-status .chevron-open.pending-chevron svg {
  transform: rotate(0deg);
  transition: all 0.5s;
}

/* Closed Arrow */
.dReLifecycle-container .collapsible-header .contract-status .chevron-close {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5.94595px @dre-rgba-color, 0px 0px 5.94595px @dre-rgba-color;
  border-radius: 60px;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.active-chevron svg,
.dReLifecycle-container .collapsible-header .contract-status .chevron-close.pending-chevron svg {
  transform: rotate(-90deg);
  transition: all 0.5s;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.active-chevron svg path {
  fill: @dre-green-2;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.pending-chevron svg path {
  fill: @dre-orange;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.pending-chevron {
  background: @dre-default-white;
  border: 3px solid @dre-orange;
}

.dReLifecycle-container .collapsible-header .contract-status .chevron-close.active-chevron {
  background: @dre-default-white;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 5px;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header p a {
  text-decoration: underline;
  color: @dre-black-2;
}

.dReLifecycle-container .collapsible-wrapper .collapsible-header .header-date p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
