@import "../themes/variables.less";

.dReLifecycle-container .stakeholders .card-content {
  flex: 1;
}

.dReLifecycle-container .stakeholders .card-content p {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  padding: 15px 0;
  border-bottom: 1px solid @dre-rgba-color;
}

.dReLifecycle-container .stakeholders .card-content p:last-child {
  border: none;
}

.dReLifecycle-container .stakeholders .card-content p span {
  display: flex;
}

.dReLifecycle-container .stakeholders .card-content .address-container {
  display: flex;
  align-items: center;
  column-gap: 3px;
}

.dReLifecycle-container .stakeholders .badge-ribbon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  margin-left: 11px;
  font-weight: 600;

  background: @dre-off-white-5;
  color: @dre-primary-color;
  border-radius: 40px;
  font-size: 12px;
}

.dReLifecycle-container .stakeholders .badge-ribbon.signing-pending {
  background: @dre-orange;
  color: @dre-default-white;
}

/* Responsive */
@media only screen and (max-width: 1300px) {
  .dReLifecycle-container .stakeholders .card-content p span {
    display: block;
  }

  .dReLifecycle-container .stakeholders .card-content p .address-container {
    display: flex;
    align-items: center;
    column-gap: 3px;
  }

  .dReLifecycle-container .stakeholders .badge-ribbon {
    margin-left: 0;
  }
}
